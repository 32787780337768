import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components';
import { getUrlParam, auth } from '@helpers';
import {
  Container,
  Hero,
  Loader,
  StaticError,
  StaticWarning,
  StaticSuccess,
  Button,
  Table,
  Amount,
  VoucherRow,
} from '@components';

import MESSAGES from '@messages';
import {
  DAY_NAMES,
  MONTH_NAMES,
  PAYMENT_STATUSES,
} from '@constants';

const StyledContainer = styled(Container)`
  text-align: center;
`;
const DetailsWraper = styled.div`
  ${(props) => props.disabled && `
    opacity: 0.5;
  `}
`;

const getControl = (location) => getUrlParam('id', location);

const Details = ({ details }) => {
  const [success, setSuccess] = useState();
  const [warning, setWarning] = useState();

  const {
    payment_url, items, amount, status, expiration_date, voucher,
  } = details || {};

  useEffect(() => {
    if (status === PAYMENT_STATUSES.START) {
      setSuccess('');
      setWarning('');
    } else if (status === PAYMENT_STATUSES.COMPLETED) {
      setSuccess(MESSAGES.PAYMENT_COMPLETED);
      setWarning('');
    } else if ([
      PAYMENT_STATUSES.REJECTED,
      PAYMENT_STATUSES.WRONG,
      PAYMENT_STATUSES.OUTDATED,
    ].includes(status)) {
      setSuccess('');
      const now = dayjs();
      const isExpired = now.isAfter(expiration_date);
      setWarning(isExpired ? MESSAGES.PAYMENT_OUTDATED : MESSAGES.PAYMENT_CANCELLED);
    }
  }, [expiration_date, status]);

  if (!details) return null;

  const disabled = warning || success;

  return (
    <>
      {success && <StaticSuccess mb={[2, 4]} fontSize={[1, 2]}>{success}</StaticSuccess>}
      {warning && <StaticWarning mb={[2, 4]} fontSize={[1, 2]}>{warning}</StaticWarning>}
      <DetailsWraper disabled={disabled}>
        <Table>
          <thead>
            <tr>
              <th>{MESSAGES.CLASS}</th>
              <th>{MESSAGES.PARTICIPANT}</th>
              <th>{MESSAGES.AMOUNT_TIMES_PRICE}</th>
            </tr>
          </thead>
          <tbody>
            {items.map(({ event, participant, amount: itemAmount }) => {
              const {
                events_length,
                month,
                summary,
                instructor,
                day_index,
                start_time,
                end_time,
              } = event;
              return (
                <tr key={`${event.id}-${participant.id}`}>
                  <td>
                    <div>
                      <strong>{summary}</strong>
                      {typeof month === 'number' ? ` (${MONTH_NAMES[month]})` : ''}
                    </div>
                    <div>
                      {`${DAY_NAMES[day_index]} ${start_time} - ${end_time}`}
                    </div>
                    {instructor && (
                      <div>
                        {MESSAGES.INSTRUCTOR}
                        :
                        {' '}
                        {instructor}
                      </div>
                    )}
                  </td>
                  <td>{participant.name}</td>
                  <td>
                    {events_length}
                    {' x '}
                    {itemAmount / events_length}
                    {MESSAGES.PLN}
                    {' '}
                    =
                    {' '}
                    <strong>
                      {itemAmount}
                      {MESSAGES.PLN}
                    </strong>
                  </td>
                </tr>
              );
            })}
            <VoucherRow voucher={voucher} />
          </tbody>
        </Table>
        <Amount>
          {amount}
        </Amount>
      </DetailsWraper>
      {!disabled && payment_url && (
        <Button
          Tag="a"
          href={payment_url}
          target="_blank"
          mt={[3, 4]}
        >
          {MESSAGES.GO_TO_DOTPAY_PAYMENT}
        </Button>
      )}
    </>
  );
};

Details.propTypes = {
  details: PropTypes.shape({}),
};

const SummaryPage = ({ location }) => {
  const [isLoading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState();
  const [details, setDetails] = useState();
  const control = getControl(location);

  const setError = (message = MESSAGES.DEFAULT_ERROR) => {
    setLoading(false);
    setGlobalError(message);
  };

  useEffect(() => {
    const token = auth.getToken();
    if (token) {
      setLoading(true);
      axios
        .get(`payment/${control}`)
        .then(({ status, data }) => {
          if (status === 200) {
            setLoading(false);
            setGlobalError(null);
            setDetails(data);
          } else {
            setError();
          }
        }).catch(({ response: { data: { statusCode } = {} } = {} }) => {
          setError(statusCode === 404 ? MESSAGES.NOT_FOUND : MESSAGES.DEFAULT_ERROR);
        });
    } else {
      setError(MESSAGES.NOT_FOUND);
    }
  }, [control]);

  return (
    <StyledContainer py={1}>
      <Hero
        title={MESSAGES.ORDER_SUMMARY}
        center
        // {...hero}
        //   seo={seo}
      />
      {/* eslint-disable-next-line no-nested-ternary */}
      {globalError
        ? <StaticError fontSize={1}>{globalError}</StaticError>
        : isLoading ? <Loader /> : <Details details={details} />}
    </StyledContainer>
  );
};
SummaryPage.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
  }),
};

export default SummaryPage;
